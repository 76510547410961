/*--------------------------------------------------------------
## Color Variable
----------------------------------------------------------------*/
$white: #ffffff;
$black:  #19ADB4;
$primary: #274760;
$secondary: rgba($primary, 0.52);
$ternary: #b7b7b7;
$forth:#8AC78B;
$border: #eaeaea;
$gray: #fafafa;
$accent: #19ADB4;
