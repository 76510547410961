.cs_pricing_card.cs_style_1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  .cs_pricing_card_head {
    padding: 30px;
    @media (max-width: 1400px) {
      padding: 30px 30px 40px;
    }
    h3 {
      margin-bottom: 0px;
      border: 1px solid #fff;
      display: inline-flex;
      border-radius: 1.6em;
      background: linear-gradient(119deg, #d2eaef 17.16%, #f8d832 89.78%);
      span {
        display: inline-flex;
        padding: 7px 20px;
        position: relative;
        z-index: 2;
        &:first-child {
          border-radius: 1.6em;
          border: 1px solid #fff;
          margin: -1px;
        }
      }
    }
    p {
      margin-top: 28px;
      margin-bottom: 0;
    }
    h2 {
      margin-top: px;
    }
  }
  .cs_pricing_card_body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 30px 30px 30px;
    border: 1px solid $accent;
    border-top: 0;
    border-radius: 0 0 20px 20px;
    gap: 30px;
    @media (max-width: 1400px) {
      padding: 40px 24px 30px;
    }
  }
  .cs_pricing_card_feature {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      padding-left: 36px;
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
    i {
      position: absolute;
      left: 0;
      color: $accent;
    }
  }
}
